import React, { createContext, useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
import { Alert, Snackbar } from "@mui/material";

import { storeData, fetchData, apiCall } from "./helpers";

// const categoryArr = [
//   { category_id: 1, category_name: "fitness" },
//   { category_id: 2, category_name: "nutrition" },
//   { category_id: 3, category_name: "yoga" },
//   { category_id: 4, category_name: "Dance" },
//   { category_id: 5, category_name: "Zumba" },
//   { category_id: 6, category_name: "MMA" },
//   { category_id: 7, category_name: "Boxing" },
//   { category_id: 8, category_name: "Calisthenics" },
//   { category_id: 9, category_name: "Pilates" },
//   { category_id: 10, category_name: "Physio" },
//   { category_id: 11, category_name: "Other" },
// ];

const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  // const uuid = fetchData("uuid") || uuidv4();
  // const ip_address = fetchData("ip_address") || getIpAddress();
  const [user, setUser] = useState(fetchData("user_data") || {});
  // const [categories, setCategories] = React.useState(categoryArr);
  const [msg, setMsg] = useState("");
  const [country, setCountry] = useState("India");
  const [countryFetched, setCountryFetched] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const access_token =
    "EAAU8kFv9tiEBOxRPOFnZAmXiWvZA9acBwl3xzOSVhOjD8hZB98TNJxprHanc2zR1XrvtisNuzPS00A8EPAZCZBlsnb87DIPBUBTPtUPUoulbOrKZCNTYNHfWv86TlXiFVQd2BMfzsiO0ZCNg6NMEEA7dOlobXs8On1Xl4kVZB9AKXqZBtl0f2lkD4iKfaDYEptpdCXQZDZD";
  const pixel_id = "1377239226322448";

  useEffect(() => {
    // if (!fetchData("uuid")) storeData("uuid", uuid);
    getIpAddress();
  }, []);

  useEffect(() => storeData("user_data", user), [user]);

  const getIpAddress = () => {
    apiCall
      .get("https://ipapi.co/json/")
      .then((res) => {
        if (res.status === 200) {
          setIpAddress(res.data.ip);
          setCountryFetched(true);
          setCountry(
            res.data.country_name === "United States"
              ? "USA"
              : res.data.country_name === "India"
              ? "India"
              : "India"
          );
        } else setCountryFetched(false);
      })
      .catch((e) => console.log(e));
  };

  // const getCategories = () => {
  //   apiCall
  //     .post("v1/user/getCategoryList", {
  //       admin_type_id: 1,
  //       key: "JWT",
  //       secret: "DOPAMINE",
  //       api_name: "getCategoryList",
  //     })
  //     .then((res) => {
  //       if (res.status === 200 && res.data.success) {
  //         res.data.data.forEach((category) => {
  //           preloadImage(category.inactive_icon);
  //           preloadImage(category.active_icon);
  //           return category;
  //         });
  //         setCategories(res.data.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        setMsg,
        country,
        setCountry,
        countryFetched,
        isRedirect,
        setIsRedirect,
        access_token,
        pixel_id,
        ipAddress,
      }}
    >
      {msg && (
        <Snackbar
          open
          autoHideDuration={2000}
          onClose={() => setMsg("")}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          message={msg}
        >
          <Alert
            severity={
              msg.includes("ucces")
                ? "success"
                : msg.includes("soon")
                ? "warning"
                : "error"
            }
          >
            {msg}
          </Alert>
        </Snackbar>
      )}
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
