import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import "./utils/styles.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PixelMeta } from "./components/V2_COMPONENTS/PixelMeta";
import mixpanel from "mixpanel-browser";

const tagManagerArgs = { gtmId: "GTM-PDNCS75", dataLayerName: "PageDataLayer" };

setTimeout(() => {
  TagManager.initialize(tagManagerArgs);
}, 15000);

mixpanel.init("4f9248ac16529581fa02e6f6ae74febc", {
  debug: true,
  track_pageview: true,
  ignore_dnt: true,
});
ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="580673275911-7vqgbcc44hllfjmbqictfakaks1vt3s5.apps.googleusercontent.com">
      <App />
      <PixelMeta />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
