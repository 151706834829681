import Footer from "../../components/footer";
const ismobile = window.innerWidth <= 768;

export const Safety = () => {
  return (
    <>
      <div
        style={{
          padding: "2% 6%",
          width: "100%",
          maxWidth: "1400px",
          color: "#444",
          fontSize: "0.9em",
          margin: ismobile ? "15% auto auto auto" : "5% auto auto auto",
          scrollSnapAlign: "start",
          scrollMarginTop: "50px",
        }}
      >
        <section>
          <h2 style={{ textAlign: "center" }}>
            Dopamine Child Safety and Protection Policy (CSAE)
          </h2>
          <center>
            <b>Effective Date: 15th February, 2025</b>
          </center>
          <center>
            <b>Last Updated: 11th February, 2025</b>
          </center>
          <h3>1. Introduction</h3>

          <p>
            Dopamine is committed to creating a safe, secure, and positive
            environment for all users, including children and adolescents. This
            Child Safety and Protection Policy (CSAE) outlines the principles,
            guidelines, and practices we follow to ensure the safety and
            well-being of minors using our platform.
          </p>
        </section>
        <section>
          <h3>2. Scope</h3>
          <p>
            This policy applies to all Dopamine users, employees, contractors,
            partners, and third-party vendors who interact with minors through
            the platform. It covers all features, including content sharing,
            community interactions, and AI-driven functionalities.
          </p>
        </section>
        <section>
          <h3>3. Definitions</h3>
          <p>
            <b>Child/Minor:</b> Any individual under the age of 18.
            <br />
            <b>Inappropriate Content:</b> Material that depicts or promotes
            violence, sexual content, harassment, bullying, substance abuse, or
            other content deemed harmful to minors.
            <br />
            <b>Personal Identifiable Information (PII):</b> Information that can
            identify a child, such as name, address, phone number, or
            geolocation data.
          </p>
        </section>
        <section>
          <h3>4. Age Restrictions and Parental Consent</h3>
          <p>
            Dopamine is designed for users aged 13 and above.
            <br />
            Users under 18 require parental or guardian consent to create an
            account.
            <br />
            Age verification measures are in place to prevent underage access.
          </p>
        </section>
        <section>
          <h3>5. Content Moderation and Community Guidelines</h3>
          <p>
            <b>Strict Moderation:</b> AI-powered and human moderation to detect
            and remove inappropriate content.
            <br />
            <b>Reporting Tools:</b> Easy-to-use reporting features for users to
            flag harmful content or behavior.
            <br />
            <b>Prohibited Content:</b> No tolerance for bullying, harassment,
            exploitation, or any form of abuse.
          </p>
        </section>
        <section>
          <h3>6. Data Privacy and Protection</h3>
          <p>
            <b>Minimal Data Collection:</b> We collect only essential data from
            minors, adhering to data minimization principles.
            <br />
            <b>Parental Controls:</b> Options for parents to monitor and manage
            their child's activities.
            <br />
            <b>Secure Storage:</b> Encrypted storage and restricted access to
            personal data.
          </p>
        </section>
        <section>
          <h3>7. Safety Features</h3>
          <p>
            <b>Restricted Communication:</b> Limited direct messaging
            capabilities for minors to prevent unsolicited contact.
            <br />
            <b>Content Filters:</b> Default safety filters to block
            age-inappropriate content.
            <br />
            <b>Wellness Check-ins:</b> AI-driven reminders promoting mental
            well-being and safe online behavior.
          </p>
        </section>
        <section>
          <h3>8. Training and Awareness</h3>
          <p>
            <b>Staff Training:</b> Regular training for staff on child safety,
            data protection, and recognizing signs of abuse.
            <br />
            <b>User Education:</b> Resources for minors and parents on online
            safety, digital well-being, and responsible usage.
          </p>
        </section>
        <section>
          <h3>9. Incident Response and Reporting</h3>
          <p>
            <b>Swift Action:</b> Immediate review and action on reported
            violations.
            <br />
            <b>Collaboration with Authorities:</b> Cooperation with law
            enforcement when necessary to address serious safety threats.
            <br />
            <b>Incident Logs:</b> Maintenance of detailed records of reported
            incidents and actions taken.
          </p>
        </section>
        <section>
          <h3>10. Policy Review and Updates</h3>
          <p>
            This policy is reviewed annually and updated as needed to comply
            with legal requirements and industry best practices.
          </p>
        </section>
        <section>
          <h3>11. Contact Information</h3>
          <p>For concerns or reports related to child safety, contact us at:</p>
          <b>Email:</b>{" "}
          <a
            href="mailto:siddhesh.g@dopamine.fit"
            style={{ color: "#ff4a01", textDecoration: "none" }}
          >
            siddhesh.g@dopamine.fit
          </a>
          <br />
          <b>Support Hotline:</b> +91-9867870802
        </section>
        <section>
          <h3>12. Compliance</h3>
          <p>
            This policy aligns with global child safety standards, including
            COPPA, GDPR-K, and Google Play CSAE requirements.
          </p>
        </section>
      </div>

      <Footer />
    </>
  );
};
